import React, {useState, useEffect} from "react";
import styled from "styled-components";
import {Container, Row, Col} from "react-bootstrap";
import Masonry from "react-masonry-component";

import {
    Section,
    Box,
    Text,
    ButtonOutline,
    ListNav,
} from "../../components/Core";
import {useWindowSize} from "../../hooks";
import {breakpoints} from "../../utils";
import {workSkills} from "../../data/workSkills";

const WorkCard = styled(Box)``;
const WorkText = styled(Box)``;

const Grid = (props) => {
    const size = useWindowSize();

    const masonryOptions = {
        transitionDuration: 1000,
    };

    return size.width < breakpoints.lg ? (
        <Masonry
            options={masonryOptions}
            className={"masonry-grid row"}
            {...props}
        />
    ) : (
        <Row {...props} />
    );
};

const Works = () => {
    const [items, setItems] = useState([]);
    const [activeLink, setActiveLink] = useState("*");

    useEffect(() => {
        setItems(workSkills);
    }, []);

    const filterBy = (cat) => {
        if (cat === "*") {
            setActiveLink("*");
            setItems(workSkills);
        } else {
            const filteredItems = workSkills.filter((item) => {
                return item.categories.indexOf(cat) !== -1;
            });
            setActiveLink(cat);
            setItems(filteredItems);
        }
    };

    return (
        <>
            {/* <!-- Works Area --> */}
            <Section className="position-relative">
                <Container>
                    <Box
                        mb="2.5rem"
                        ml="-1.75rem"
                        className="d-flex justify-content-center"
                    >
                        <ListNav className="nav">
                            <li className="nav-item">
                                <a
                                    className={`nav-link font-weight-bold text-uppercase ${
                                        activeLink === "*" ? "active" : null
                                    }`}
                                    onClick={(e) => {
                                        e.preventDefault();
                                        filterBy("*");
                                    }}
                                >
                                    All works
                                </a>
                            </li>
                            <li className="nav-item">
                                <a
                                    className={`nav-link font-weight-bold text-uppercase ${
                                        activeLink === "branding" ? "active" : null
                                    }`}
                                    onClick={(e) => {
                                        e.preventDefault();
                                        filterBy("branding");
                                    }}
                                >
                                    Branding
                                </a>
                            </li>
                            <li className="nav-item">
                                <a
                                    className={`nav-link font-weight-bold text-uppercase ${
                                        activeLink === "ux-design" ? "active" : null
                                    }`}
                                    onClick={(e) => {
                                        e.preventDefault();
                                        filterBy("ux-design");
                                    }}
                                >
                                    UX Design
                                </a>
                            </li>
                            <li className="nav-item">
                                <a
                                    className={`nav-link font-weight-bold text-uppercase ${
                                        activeLink === "photography" ? "active" : null
                                    }`}
                                    onClick={(e) => {
                                        e.preventDefault();
                                        filterBy("photography");
                                    }}
                                >
                                    Photography
                                </a>
                            </li>
                        </ListNav>
                    </Box>
                </Container>
                <Container fluid>
                    <Grid>
                        {items.map((item, index) => (
                            <Col lg="3" md="6" key={index} className="filtr-item">
                                <WorkCard className="position-relative" mb="30px">
                                    {item.progressBars.map((progressBar) => (
                                        <div><p>{progressBar.label}</p>
                                            <div className="progress">

                                                <div
                                                    className="progress-bar progress-bar-striped progress-bar-animated"
                                                    role="progressbar"
                                                    aria-label={progressBar.label}
                                                    style={{width: progressBar.percentage + '%'}}
                                                    aria-valuenow={progressBar.percentage}
                                                    aria-valuemin="0"
                                                    aria-valuemax="100">{progressBar.percentage}
                                                </div>
                                                <br/>
                                            </div>
                                        </div>
                                    ))}

                                    <Box pt="2.125rem">
                                        <WorkText className="overflow-hidden text-center">
                                            <Text variant="tag" mb="0.5rem">
                                                {item.categories[0]}
                                            </Text>
                                        </WorkText>
                                    </Box>
                                </WorkCard>
                            </Col>
                        ))}
                    </Grid>
                    <Box pt="3rem" className="text-center">
                        <ButtonOutline>Load more works</ButtonOutline>
                    </Box>
                </Container>
            </Section>
        </>
    );
};

export default Works;
