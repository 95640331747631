import React from "react";

import PageWrapper from "../components/PageWrapper";
import Hero from "../sections/grid-skills/Hero";
import {Element} from "react-scroll";
import Works from "../sections/grid-skills/Works";
import Contact from "../sections/grid-skills/Contact";




const SkillsPage = () => {
    return (
        <>
            <PageWrapper darkTheme>
                <Hero />
                <Element name="works">
                    <Works />
                </Element>
                <Contact />
            </PageWrapper>
        </>
    );
};

export default SkillsPage;
