export const workSkills = [
    {
        brand: `javaList`,
        slug: `javaList`,
        title: `Adaptable but Identifiable Product for Kandinsky.`,
        categories: [`soft`],
        progressBars: [
            {label: 'Java 8 / 11', percentage: 70, color: 'red'},
            {label: 'Swing', percentage: 70, color: 'red'},
            {label: 'JavaFx', percentage: 70, color: 'red'},
            {label: 'Maven', percentage: 75},
        ]
    },
    {
        brand: `Travel App`,
        slug: `travel-app`,
        title: `Adaptable but Identifiable Product for Kandinsky.`,
        categories: [`technology`],
        progressBars: [
            {label: 'JPA, {OpenJpa, Hibernate}', percentage: 60},
            {label: 'JSF', percentage: 70},
            {label: 'Primefaces', percentage: 75},
            {label: 'JAX-RS (REST) / JAX-WS (SOAP)', percentage: 65},
            {label: 'EJB / CDI', percentage: 65},
        ]
    },
    {
        brand: `Shopper`,
        slug: `shopper`,
        title: `Adaptable but Identifiable Product for Kandinsky.`,
        categories: [`language`],
        progressBars: [
            {label: 'SpringBoot', percentage: 70, color: 'red'},
            {label: 'SpringData', percentage: 75, color: 'green'},
            {label: 'SpringBatch', percentage: 75}]
    },
    {
        brand: `Javascript`,
        slug: `Javascript`,
        title: `Adaptable but Identifiable Product for Kandinsky.`,
        categories: [`language`],
        progressBars: [
            {label: 'Angular', percentage: 60},
            {label: 'ECMAscript 5 / 6 ', percentage: 55, color: 'red' },
            {label: 'Typerscript', percentage: 55, color: 'red'},
            {label: 'MaterialNg/PrimeNg', percentage: 55, color: 'red'},
            {label: 'Node Js', percentage: 55, color: 'red'},
            {label: 'NPM', percentage: 55, color: 'red'},
            {label: 'NgRx', percentage: 55, color: 'red'},
            {label: 'RxJs', percentage: 55, color: 'red'},]
    },
    {
        brand: `Android`,
        slug: `Android`,
        title: `Adaptable but Identifiable Product for Kandinsky.`,
        categories: [`language`],
        progressBars: [
            {label: 'Android', percentage: 40},
            {label: 'Material', percentage: 35},
            {label: 'Android studio', percentage: 40},
            {label: 'Room Persistence Library', percentage: 20},]
    },
    {
        brand: `Javascript`,
        slug: `Javascript`,
        title: `Adaptable but Identifiable Product for Kandinsky.`,
        categories: [`language`],
        progressBars: [
            {label: 'Android', percentage: 40},
            {label: 'Material', percentage: 35},
            {label: 'Android studio', percentage: 40},
            {label: 'Room Persistence Library', percentage: 20},]
    },{
        brand: `Other`,
        slug: `Other`,
        title: `Adaptable but Identifiable Product for Kandinsky.`,
        categories: [`language`],
        progressBars: [
            {label: 'Git', percentage: 70},
            {label: 'SQL', percentage: 70},
            {label: 'OpenAPI 3.0', percentage: 30},
            {label: 'Bash shell', percentage: 30},
            {label: 'JSON/XML', percentage: 65},]
    },
];

/*



public applicationServers: KnowledgeBar[] = [
  {label: 'Websphere liberty profile', percentage: 65},
  {label: 'Open liberty', percentage: 65},
  {label: 'Tomcat', percentage: 35},
  {label: 'Nginx', percentage: 35},
];
public softwares: KnowledgeBar[] = [
  {label: 'Intellij / Eclipse', percentage: 65},
  {label: 'Google chrome dev', percentage: 35},
  {label: 'Soap ui', percentage: 65},
  {label: 'DBeaver', percentage: 35},
  {label: 'SQL Power Architect', percentage: 35},
  {label: 'Postman', percentage: 35},
];
public operationTools: KnowledgeBar[] = [
  {label: 'Docker', percentage: 45},
  {label: 'Virtual box', percentage: 50},
  {label: 'Sonarqube', percentage: 40},
  {label: 'Gitlab', percentage: 65},
  {label: 'Jira', percentage: 65},
];
public OperatingSystems: KnowledgeBar[] = [
  {label: 'Linux : Ubuntu / Kali', percentage: 55},
  {label: 'Windows', percentage: 70},
];
public humanSkills: KnowledgeBar[] = [
  {label: 'Adaptability', percentage: 70},
  {label: 'Communication', percentage: 65},
  {label: 'Learning', percentage: 80},
  {label: 'Self-learner', percentage: 80},
  {label: 'Flexible', percentage: 75},
  {label: 'Stress and time management', percentage: 70},
];
public languages: KnowledgeBar[] = [
  {label: 'French : ( Native )', percentage: 90},
  {label: 'English : ( Professional working proficiency )', percentage: 65},
  {label: 'Dutch : ( Elementary proficiency )', percentage: 30},
  {label: 'Russian', percentage: 30}
];
public operatingSystems: KnowledgeBar[] = [
  {label: 'Windows', percentage: 90},
  {label: 'Linux', percentage: 50},
  {label: 'Android', percentage: 70},
  {label: 'IOS', percentage: 45},
];
public otherSkills: KnowledgeBar[] = [
  {label: 'UML', percentage: 60},
  {label: 'Solidworks', percentage: 30},
];
*/
